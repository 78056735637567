import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import backend from "i18next-http-backend";

export const options = {
  fallbackNS: "common",
  fallbackLng: "zh",
  defaultNS: "common",
  lng: "zh",
  ns: ["common"],
  react: {
    withRef: true,
    wait: true,
    useSuspense: false,
  },
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  backend: {
    loadPath: "/file/locales/{{lng}}/{{ns}}.json",
  },
};

const i18nInstance = i18next;

i18nInstance
  .use(backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(options);

export default i18nInstance;
