import { observable, action } from "mobx";
import axios from "axios";
import { autobind } from "react-decoration";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

class ReaderStore {
  @observable auth = false;
  @observable info = {};
  @observable cookies = {};
  @observable session = {};

  @action.bound
  setAuth = (auth = false) => {
    this.auth = auth;
  };

  @action.bound
  setInfo = (info) => {
    this.info = info;
  };

  @action.bound
  setCookies = (cookies) => {
    this.cookies = cookies;
  };

  @action.bound
  setSession = (session) => {
    this.session = session;
  };

  @action.bound
  async syncSessionCookie() {
    await axios
      .get(`/api/jumperrwdWs/session?${this.getRandomValue()}`)
      .then(async (res) => {
        const { session } = res.data;
        if (session !== undefined) {
          if (session.reload !== "true") {
            if (!session.csrfToken || session.csrfToken === "") {
              session.csrfToken =
                "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                  /[xy]/g,
                  function (c) {
                    var randomValuesArray = new Int32Array(1);
                    var crypto = window.crypto || window.msCrypto;
                    var randomValues =
                      crypto.getRandomValues(randomValuesArray);
                    var random = randomValues[0] / (0xffffffff + 1);
                    if (random < 0) {
                      random = -random;
                    }
                    var r = (random * 16) | 0,
                      v = c === "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                  }
                );
              await axios
                .get(
                  "/api/jumperrwdWs/setSession?key=csrfToken&value=" +
                    session.csrfToken
                )
                .then((res) => {});
            }
            this.session = session;
            this.auth = session.loginType || false;
          }
        } else {
          this.auth = false;
        }
      })
      .catch((error) => {
        //console.log('err=>' + error);
      });
  }

  @autobind
  getRandomValue() {
    let randomValuesArray = new Int32Array(1);
    let crypto = window.crypto || window.msCrypto;
    if (crypto) {
      let randomValues = crypto.getRandomValues(randomValuesArray);
      var random = randomValues[0] / (0xffffffff + 1);
      if (random < 0) {
        random = -random;
      }
      return random;
    }
    return 0;
  }
}

export default ReaderStore;
